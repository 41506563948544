import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H3, H5 } from "../../../../AbstractElements";
import axios from "axios";

interface UtilizationData {
  name: string;
  BilledHours: number;
  ExpectedHours: string;
  percentage: number;
}

const UtilizationReport = () => {
  const [data, setData] = useState<UtilizationData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof UtilizationData;
    direction: "asc" | "desc";
  } | null>(null);
  const [selectedMonth, setSelectedMonth] = useState("Current"); // Dropdown state
  const [monthOptions, setMonthOptions] = useState<string[]>([]); // Dropdown options
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      let params = {};

      // Set parameters based on dropdown selection
      if (selectedMonth === "Current") {
        params = {}; // No parameters for 'Current'
      } else {
        const [month, year] = selectedMonth.split("-");
        params = { month: parseInt(month), year: parseInt(year) }; // Set month and year
      }

      const response = await axios.get("/utilization", { params });
      console.log(response);
      const results = response.data;
      setData(results);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth]); // Re-fetch when selectedMonth changes

  // Generate month options for dropdown
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Get current month (0-based)
    const year = currentDate.getFullYear();

    // Start with "Current" as the default option
    let options = ["Current"];

    // Add previous months starting from current month, and go back as far as January 2025
    for (let month = currentMonth; month >= 0; month--) {
      if (new Date(year, month).getFullYear() >= 2025) {
        options.push(`${month + 1}-${year}`); // Convert 0-based month to 1-based
      }
    }

    setMonthOptions(options);
  }, []);

  const sortData = (key: keyof UtilizationData) => {
    if (!data) return;

    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const getCurrentMonth = () => {
    if (selectedMonth === "Current") {
      const date = new Date();
      return date.toLocaleString("default", { month: "long" });
    } else {
      return monthNames[parseInt(selectedMonth.split("-")[0]) - 1];
    }
  };

  if (loading) {
    return <div>Loading Utilization Report...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const totalBilledHours =
    data?.reduce((sum, item) => sum + item.BilledHours, 0) || 0;
  const totalExpectedHours =
    data?.reduce(
      (sum, item) => sum + parseFloat(item.ExpectedHours || "0"),
      0
    ) || 0;
  const totalUtilization =
    totalExpectedHours > 0
      ? ((totalBilledHours / totalExpectedHours) * 100).toFixed(1)
      : "0.0";

  return (
    <Col xs='12'>
      <style>{`
        .utilization-list {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
          font-size: 1rem;
          text-align: left;
        }
        .utilization-list th, .utilization-list td {
          padding: 12px 15px;
          border: 1px solid #ddd;
          cursor: pointer;
        }
        .utilization-list th {
          background-color: #f4f4f4;
          font-weight: bold;
        }
        .utilization-list .even-row {
          background-color: #f9f9f9;
        }
        .utilization-list .odd-row {
          background-color: #fff;
        }
        .utilization-list tr:hover {
          background-color: #f1f1f1;
        }
      `}</style>
      <Row>
        <Col xs='12'>
          <H3 className='font-primary'>
            Utilization Report - {getCurrentMonth()}
          </H3>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <H5 className='font-primary'>Select Month and Year</H5>
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            {monthOptions.map((option, index) => (
              <option key={index} value={option}>
                {option === "Current" ? "Current" : option}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <H5 className='font-primary'>Total</H5>
          <p>Total Billed Hours: {totalBilledHours}</p>
          <p>Total Expected Hours: {totalExpectedHours}</p>
          <p>Total Utilization: {totalUtilization}%</p>
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <table className='utilization-list'>
            <thead>
              <tr>
                <th onClick={() => sortData("name")}>Name</th>
                <th onClick={() => sortData("BilledHours")}>Billed Hours</th>
                <th>Expected Hours</th>
                <th onClick={() => sortData("percentage")}>Utilization</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>{item.name}</td>
                  <td>{item.BilledHours}</td>
                  <td>{item.ExpectedHours}</td>
                  <td>{item.percentage}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Col>
  );
};

export default UtilizationReport;
