import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import axios from "axios";

export const statusOrder = [
  "Prospecting",
  "Identified",
  "Validated",
  "Interviewing",
  "Client Review",
  "Submitted",
  "Verbal Won",
];

const additionalStatusCategories = ["Placed", "Lost", "Hold/Archive"];

interface PipeLineReportData {
  id: number;
  clientCorporation: {
    id: number;
    name: string;
  };
  title: string;
  owner: {
    id: number;
    firstName: string;
    lastName: string;
  };
  status: string;
  customFloat3: number;
  dateAdded: string;
  _score: number;
}

const PipeLineReport = () => {
  const [data, setData] = useState<PipeLineReportData[]>([]);
  const [filteredData, setFilteredData] = useState<PipeLineReportData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [chartData, setChartData] = useState<ApexOptions | null>(null);
  const [owners, setOwners] = useState<{ id: number; name: string }[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<number | "All">("All");
  const [totals, setTotals] = useState<Record<string, number>>({});
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [statusJobs, setStatusJobs] = useState<PipeLineReportData[]>([]);

  const sortPipelineData = (
    data: PipeLineReportData[]
  ): PipeLineReportData[] => {
    return data.sort(
      (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
    );
  };

  const groupTotalsByStatus = (
    data: PipeLineReportData[]
  ): Record<string, number> => {
    const totals: Record<string, number> = [
      ...statusOrder,
      ...additionalStatusCategories,
    ].reduce((acc, status) => ({ ...acc, [status]: 0 }), {});

    data.forEach((item) => {
      if (totals.hasOwnProperty(item.status)) {
        totals[item.status] += item.customFloat3;
      }
    });

    return totals;
  };

  const updateChart = (filteredResults: PipeLineReportData[]) => {
    const newTotals = groupTotalsByStatus(filteredResults);
    setTotals(newTotals);

    setChartData({
      series: [
        {
          name: "Total Value",
          data: statusOrder.map((status) => newTotals[status]),
        },
      ],
      chart: {
        type: "bar",
        height: 500,
        toolbar: { show: true },
        events: {
          dataPointSelection: (_, __, config) => {
            const selected = statusOrder[config.dataPointIndex];
            setSelectedStatus(selected);
            setStatusJobs(
              filteredResults.filter((job) => job.status === selected)
            );
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: true,
          distributed: true,
          barHeight: "80%",
          isFunnel: true,
        },
      },
      colors: [
        "#F44F5E",
        "#E55A89",
        "#D863B1",
        "#CA6CD8",
        "#B57BED",
        "#8D95EB",
        "#62ACEA",
        "#4be6a3",
      ],
      dataLabels: {
        enabled: true,
        formatter: (val) => `$${val.toLocaleString()}`,
        dropShadow: { enabled: true },
        style: { fontSize: "14px", fontWeight: "bold" },
      },
      title: { text: "Pipeline Report", align: "center" },
      xaxis: {
        categories: statusOrder,
        labels: { show: false },
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (_, index) => statusOrder[index],
          style: { fontSize: "14px", fontWeight: "bold" },
        },
      },
      legend: { show: true },
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/pipeline");
      const results = sortPipelineData(response.data);

      const uniqueOwners = Array.from(
        new Map(
          results.map((item) => [
            item.owner.id,
            {
              id: item.owner.id,
              name: `${item.owner.firstName} ${item.owner.lastName}`,
            },
          ])
        ).values()
      );

      setOwners([
        { id: "All" as unknown as number, name: "All Owners" },
        ...uniqueOwners,
      ]);
      setData(results);
      setFilteredData(results);
      updateChart(results);
      setLoading(false);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedOwner === "All") {
      setFilteredData(data);
      updateChart(data);
    } else {
      const filteredResults = data.filter(
        (item) => item.owner.id === selectedOwner
      );
      setFilteredData(filteredResults);
      updateChart(filteredResults);
    }
  }, [selectedOwner, data]);

  return (
    <Card>
      <CardHeader>{chartData?.title?.text}</CardHeader>
      <CardBody>
        <FormGroup>
          <Label for='ownerSelect'>Filter by Owner:</Label>
          <Input
            type='select'
            id='ownerSelect'
            value={selectedOwner}
            onChange={(e) =>
              setSelectedOwner(
                e.target.value === "All" ? "All" : parseInt(e.target.value)
              )
            }
          >
            {owners.map((owner) => (
              <option key={owner.id} value={owner.id}>
                {owner.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <ReactApexChart
              options={chartData!}
              series={chartData!.series}
              type='bar'
              height={500}
            />
            <div className='totals-container'>
              <p>
                <strong>Placed:</strong> $
                {totals["Placed"]?.toLocaleString() || 0}
              </p>
              <p>
                <strong>Lost:</strong> ${totals["Lost"]?.toLocaleString() || 0}
              </p>
              <p>
                <strong>Hold/Archive:</strong> $
                {totals["Hold/Archive"]?.toLocaleString() || 0}
              </p>
            </div>
          </>
        )}

        {selectedStatus && (
          <>
            <h5 className='mt-4'>Jobs in "{selectedStatus}"</h5>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Job Title</th>
                  <th>Client</th>
                  <th>Owner</th>
                  <th>Value</th>
                  <th>Date Added</th>
                </tr>
              </thead>
              <tbody>
                {statusJobs.length > 0 ? (
                  statusJobs.map((job) => (
                    <tr key={job.id}>
                      <td>{job.title}</td>
                      <td>{job.clientCorporation.name}</td>
                      <td>{`${job.owner.firstName} ${job.owner.lastName}`}</td>
                      <td>${job.customFloat3.toLocaleString()}</td>
                      <td>{new Date(job.dateAdded).toLocaleDateString()}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      No jobs found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PipeLineReport;
