import React from "react";
import { Container, Row } from "reactstrap";
import OpenJobsReport from "../OpenJobsReports";
import UtilizationReport from "../UtilizationReports";
import PipelineReport from "../PipeLineReport";
import Header from "../../../../../Layout/Header/Header";

interface ReportWizardContainerProps {
  reportType: "openJobs" | "utilization" | "pipeline";
  filterStatus?: string;
  reportTitle?: string;
}

const ReportWizardContainer: React.FC<ReportWizardContainerProps> = ({
  reportType,
  filterStatus,
  reportTitle,
}) => {
  console.log("ReportWizard:", reportType, filterStatus);

  const renderReport = () => {
    switch (reportType) {
      case "openJobs":
        return (
          <OpenJobsReport
            filterStatus={filterStatus}
            reportTitle={reportTitle}
          />
        );
      case "utilization":
        return <UtilizationReport />;
      case "pipeline":
        return <PipelineReport />;
      default:
        return <div>Invalid report type specified.</div>;
    }
  };

  return (
    <>
      <Container fluid>
        <Header />
        <Row>{renderReport()}</Row>
      </Container>
    </>
  );
};

export default ReportWizardContainer;
