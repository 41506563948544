import { useLocation, Navigate } from "react-router-dom";
import queryString from "query-string";
import Login from "../Component/Authentication/Login";
import Default from "../Component/Dashboard/Default/Default";
import FormWizardOneContainer from "../Component/Forms/FormsLayout/FormWizardOne/FormWizardOne";
import ReportWizardContainer from "../Component/Dashboard/Default/HDMReports/ReportWizard/ReportWizardContainer";
import { query } from "express";

const CustomRouter = () => {
  const location = useLocation();
  const login = localStorage.getItem("login"); // Check authentication status
  const { pathname, search } = location; // Capture the intended path

  const queryParams = queryString.parse(search);
  const filterStatus = queryParams.filterStatus as string | undefined; // Safely handle query parameter
  const reportTitle = queryParams.reportTitle as string | undefined; // Safely handle query parameter
  // Define route mapping logic
  if (!login) {
    localStorage.setItem("redirectAfterLogin", pathname + search);
    console.log("Redirecting to login page");
    console.log(pathname, search);
    return <Login />;
  } else {
    console.log("Authenticated route. Passing in filterStatus", filterStatus);
  }

  // Authenticated route logic
  switch (pathname) {
    case "/dashboard/default":
      return <Default />;
    case "/pipeline-report":
      return <ReportWizardContainer reportType='pipeline' />;
    case "/utilization-report":
      return <ReportWizardContainer reportType='utilization' />;
    case "/jobs-report":
      return (
        <ReportWizardContainer
          reportType='openJobs'
          filterStatus={filterStatus}
          reportTitle={reportTitle}
        />
      );
    case "/screening":
      return <FormWizardOneContainer />;
    case "/screening/form":
      return <FormWizardOneContainer />;

    default:
      // Redirect to default dashboard if route is not matched
      return <Navigate to='/dashboard/default' replace />;
  }
};

export default CustomRouter;
