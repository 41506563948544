import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Tabs,
  Select,
  Spin,
  Radio,
  Modal,
  Rate,
} from "antd";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CommonTourSocialMedia from "../../../../BonusUi/Tour/Common/CommonTourSocialMedia";
import JobDescription from "../../../../Miscellaneous/JobSearch/JobDetails/MainCard/JobDescription";

const { TabPane } = Tabs;
const { Option } = Select;

type ClientCorporation = {
  id: number;
  name: string;
};
type Submission = {
  id: number;
  candidate: Candidate;
};

type Job = {
  id: number;
  title: string;
  clientCorporation: ClientCorporation;
  submissions: {
    candidates: {
      total: number;
      data: Submission[]; // Array of submission objects
    };
  };
  description: string;
};

type Screener = {
  id: number;
  firstName: string;
  lastName: string;
};
type Candidate = {
  id: number;
  firstName: string;
  lastName: string;
  comments: string;
};

const Screening: React.FC = () => {
  const [screenerForm] = useForm();
  const [clientForm] = useForm();
  const [jobForm] = useForm();
  const [candidateForm] = useForm();
  const [screeningNotesForm] = useForm();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
  const [clientCorporations, setClientCorporations] = useState<
    ClientCorporation[]
  >([]);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [screeners, setScreeners] = useState<Screener[]>([]);
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [selectedJobObject, setSelectedJobObject] = useState<Job | null>(null);
  const [candidateDescription, setCandidateDescription] = useState<
    string | null
  >(null);
  const [jobDescription, setJobDescription] = useState<string | null>(null);
  const [submissionNotes, setSubmissionNotes] = useState<string | null>(null);

  const [formScreeningNotes, setFormScreeningNotes] = useState<string>("");
  const [pros, setPros] = useState<string>("");
  const [cons, setCons] = useState<string>("");
  const [rating, setRating] = useState<number | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitHidden, setSubmitHidden] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Making a request to the `/open-jobs` endpoint provided by your server
        const jobsResponse = await axios.get("/open-jobs-limited");
        const jobsData = jobsResponse.data;
        // Get unique client corporations from jobs data
        const uniqueClients: ClientCorporation[] = Array.from(
          new Map<number, ClientCorporation>(
            (jobsData || []).map((job: Job) => [
              job.clientCorporation.id,
              job.clientCorporation,
            ])
          ).values()
        );

        if (jobsData) {
          setJobs(jobsData);
          setFilteredJobs(jobsData); // Initially, all jobs are available
        }
        setClientCorporations(uniqueClients);
      } catch (error) {
        console.error("Error fetching jobs data:", error);
      }

      try {
        // Making a request to the `/screeners` endpoint provided by your server
        const screenersResponse = await axios.get("/screeners");
        const screenerData = screenersResponse.data;
        setScreeners(screenerData);
      } catch (error) {
        console.error("Error fetching screener data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Effect to filter jobs based on selected client corporation
  useEffect(() => {
    if (selectedClient) {
      const filtered = jobs.filter(
        (job) => job.clientCorporation.id === parseInt(selectedClient)
      );
      setFilteredJobs(filtered);
    } else {
      setFilteredJobs(jobs);
    }
  }, [selectedClient, jobs]);

  const onFinish = async (values: any) => {
    setSubmitHidden(true);
    const screenerValues = screenerForm.getFieldsValue();
    const clientValues = clientForm.getFieldsValue();
    const jobValues = jobForm.getFieldsValue();
    const candidateValues = candidateForm.getFieldsValue();
    const screeningNotesValues = screeningNotesForm.getFieldsValue();

    // Combine all form values into a single JSON object
    const consolidatedFormValues = {
      jobObject: selectedJobObject,
      screener: screenerValues.screenerId,
      clientCorporation: clientValues.clientCorporation,
      job: jobValues.jobTitle,
      candidate: candidateValues.candidateId,
      screeningNotes: {
        comments: formScreeningNotes,
        pros: pros,
        cons: cons,
        rating: rating,
        recommend: screeningNotesValues.screeningNotesRecommend,
      },
    };

    try {
      const response = await axios.post(
        "/complete-screening",
        consolidatedFormValues
      );
      console.log("Server Response:", response.data);
      screenerForm.resetFields();
      clientForm.resetFields();
      jobForm.resetFields();
      candidateForm.resetFields();
      screeningNotesForm.resetFields();

      // Show modal
      setIsModalVisible(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error sending data to server:", error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    } finally {
      setSubmitHidden(false);
    }
  };

  const handleClientChange = (value: string) => {
    jobForm.resetFields();
    candidateForm.resetFields();
    setSelectedClient(value);
  };

  if (loading) {
    return <Spin tip='Loading data...' />;
  }

  const handleJobChange = (jobId: number) => {
    const selectedJob = jobs.find((job) => job.id === jobId);
    candidateForm.resetFields();
    setSelectedJobObject(selectedJob || null);
    if (selectedJob) {
      console.log("Job Submissions:", selectedJob.submissions);

      // Explicitly type candidateData
      let candidateData: {
        id: number;
        firstName: string;
        lastName: string;
        comments: string;
      }[] = [];

      // Define the type for the candidateObject
      type CandidateObjectType = {
        id: number;
        candidate: {
          id: number;
          firstName: string;
          lastName: string;
        };
        comments: string;
      };

      const candidateObject = selectedJob.submissions?.candidates?.data;

      if (candidateObject) {
        if (Array.isArray(candidateObject)) {
          // If data is an array, process each submission
          candidateData = candidateObject.map((submission) => ({
            id: submission.candidate.id,
            firstName: submission.candidate.firstName,
            lastName: submission.candidate.lastName,
            comments: submission.candidate.comments,
          }));
        } else if (typeof candidateObject === "object") {
          // Use type assertion to tell TypeScript the expected structure
          const singleCandidate = candidateObject as CandidateObjectType;
          console.log("Logging Candidate Object:", singleCandidate);

          // Process the single candidate object
          candidateData = [
            {
              id: singleCandidate.candidate.id,
              firstName: singleCandidate.candidate.firstName,
              lastName: singleCandidate.candidate.lastName,
              comments: singleCandidate.comments,
            },
          ];
        }
      }

      // Set state with candidate data
      setCandidates(candidateData);

      // Set the job description if it exists
      setJobDescription(selectedJob.description || "No description available.");
    } else {
      // Reset state if no job is selected
      setCandidates([]);
      setJobDescription(null);
    }
  };

  const handleCandidateChange = async (candidateId: number) => {
    try {
      const response = await axios.get(`/candidate?id=${candidateId}`);
      const { description } = response.data.data;

      setCandidateDescription(description); // Update the state with fetched description
      const subNotes = candidates.find(
        (candidate) => candidate.id === candidateId
      )?.comments;

      console.log(
        "Submission:",
        candidates.find((candidate) => candidate.id === candidateId)
      );

      setSubmissionNotes(subNotes ?? null);
    } catch (error) {
      console.error("Error fetching candidate description:", error);
      setCandidateDescription(null); // Reset the state in case of an error
    }
  };

  return (
    <Card
      title='Screening Form'
      bordered={false}
      style={{ maxWidth: 800, margin: "auto" }}
    >
      <Tabs defaultActiveKey='1'>
        <TabPane tab='Screener' key='1'>
          <Form form={screenerForm} layout='vertical' onFinish={onFinish}>
            <Form.Item
              label='Screener'
              name='screenerId'
              rules={[{ required: true, message: "Please select a screener" }]}
            >
              <Select
                showSearch
                placeholder='Select Screener'
                optionFilterProp='children'
                filterOption={(input, option) => {
                  // Safely handle children as an array or a stringn        bzsxa
                  const childrenText = Array.isArray(option?.children)
                    ? (
                        option?.children?.join("") as unknown as string
                      )?.toLowerCase()
                    : (option?.children as unknown as string)?.toLowerCase() ||
                      "";
                  return childrenText?.includes(input.toLowerCase()) || false;
                }}
              >
                {screeners.map((screener) => (
                  <Option key={screener.id} value={screener.id}>
                    {`${screener.firstName} ${screener.lastName}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab='Client' key='2'>
          <Form form={clientForm} layout='vertical' onFinish={onFinish}>
            <Form.Item
              label='Client'
              name='clientCorporation'
              rules={[
                {
                  required: true,
                  message: "Please select a client corporation",
                },
              ]}
            >
              <Select
                showSearch
                placeholder='Select Client'
                onChange={handleClientChange}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  // Safely handle children as an array or a stringn        bzsxa
                  const childrenText = Array.isArray(option?.children)
                    ? (
                        option?.children?.join("") as unknown as string
                      )?.toLowerCase()
                    : (option?.children as unknown as string)?.toLowerCase() ||
                      "";
                  return childrenText?.includes(input.toLowerCase()) || false;
                }}
              >
                {clientCorporations.map((client) => (
                  <Option key={client.id} value={client.id.toString()}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab='Job' key='3'>
          <Form form={jobForm} layout='vertical' onFinish={onFinish}>
            <Form.Item
              label='Job Title'
              name='jobTitle'
              rules={[{ required: true, message: "Please select a job title" }]}
            >
              <Select
                showSearch
                placeholder='Select Job Title'
                onChange={(value) => handleJobChange(value)}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  // Safely handle children as an array or a stringn        bzsxa
                  const childrenText = Array.isArray(option?.children)
                    ? (
                        option?.children?.join("") as unknown as string
                      )?.toLowerCase()
                    : (option?.children as unknown as string)?.toLowerCase() ||
                      "";
                  return childrenText?.includes(input.toLowerCase()) || false;
                }}
              >
                {filteredJobs.map((job) => (
                  <Option key={job.id} value={job.id}>
                    {job.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Card
              title='Job Description'
              bordered={true}
              style={{ maxWidth: 800, margin: "auto" }}
            >
              {jobDescription ? (
                <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
              ) : (
                <p>No job description available.</p>
              )}
            </Card>
          </Form>
        </TabPane>

        <TabPane tab='Candidate' key='4'>
          <Form form={candidateForm} layout='vertical' onFinish={onFinish}>
            <Form.Item
              label='Candidate'
              name='candidateId'
              rules={[{ required: true, message: "Please select a candidate" }]}
            >
              <Select
                placeholder='Select Candidate'
                showSearch
                optionFilterProp='children'
                onChange={(value) => handleCandidateChange(value)} // Call function on candidate selection
                filterOption={(input, option) => {
                  // Safely handle children as an array or a stringn        bzsxa
                  const childrenText = Array.isArray(option?.children)
                    ? (
                        option?.children?.join("") as unknown as string
                      )?.toLowerCase()
                    : (option?.children as unknown as string)?.toLowerCase() ||
                      "";
                  return childrenText?.includes(input.toLowerCase()) || false;
                }}
              >
                {candidates.map((candidate) => (
                  <Option key={candidate.id} value={candidate.id}>
                    {candidate.firstName} {candidate.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Card
              title='Submission Notes'
              bordered={true}
              style={{ maxWidth: 800, margin: "auto" }}
            >
              {submissionNotes ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: submissionNotes ? submissionNotes : "",
                  }}
                />
              ) : (
                <p>No Submission Notes available.</p>
              )}
            </Card>
          </Form>
          <Card
            title='Candidate Resume'
            bordered={true}
            style={{ maxWidth: 800, margin: "auto" }}
          >
            {candidateDescription ? (
              <div dangerouslySetInnerHTML={{ __html: candidateDescription }} />
            ) : (
              <p>No candidate selected or no description available.</p>
            )}
          </Card>
        </TabPane>

        <TabPane tab='Screening Notes' key='5'>
          <Form form={screeningNotesForm} layout='vertical' onFinish={onFinish}>
            <Form.Item label='Screening Notes' name='screeningNotes'>
              <ReactQuill
                value={formScreeningNotes}
                onChange={setFormScreeningNotes}
              />
            </Form.Item>
            <Form.Item label='Pros' name='screeningNotesPros'>
              <ReactQuill value={pros} onChange={setPros} />
            </Form.Item>
            <Form.Item label='Cons' name='screeningNotesCons'>
              <ReactQuill value={cons} onChange={setCons} />
            </Form.Item>
            <Form.Item
              label="Based on your knowledge of the client, what's your overall fit score (1-5, 5 being best)"
              name='screeningNotesRating'
            >
              <Rate
                count={5}
                tooltips={[
                  "1-Poor",
                  "2-Fair",
                  "3-Good",
                  "4-Very Good",
                  "5-Excellent",
                ]}
                onChange={setRating}
              />
              Please ensure your comments above explain this rating
            </Form.Item>
            <Form.Item label='Recommend' name='screeningNotesRecommend'>
              <Radio.Group>
                <Radio value='yes'>Yes</Radio>
                <Radio value='no'>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
      <Modal
        title='Thank You'
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        okText='Close'
      >
        <p>
          Thanks for submitting your screening. Notes will appear in Slack ~Ken
        </p>
      </Modal>

      <Button
        type='primary'
        onClick={() => screenerForm.submit()}
        style={{ marginTop: "20px" }}
        hidden={submitHidden}
      >
        Submit Screening Form
      </Button>
    </Card>
  );
};

export default Screening;
